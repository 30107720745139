import React, { useState, useEffect } from "react";
import "./css/App.css";
import Navbar from "./components/navbar";
import Footer from "./components/footer";

function App() {
  return (
    <React.Fragment>
      <Navbar />
      <div className="zc-page">
        <h1>Welcome!</h1>
        <p>
          Thank you for visiting, unfortunately this website is under
          development and not ready for public use.
        </p>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default App;
