import React, { useState, useEffect } from "react";
import API from "../API-service";
import { useCookies } from "react-cookie";

// css files
import "../css/App.css";
import "../css/mealplanner.css";
import "../css/recipe.css";

// imported components
import Table from "react-bootstrap/Table";
import Navbar from "../components/navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RecipeDetails from "../components/recipe-details";
import ShoppingIngredientList from "../components/shopping-ingredient-list";
import PlannedRecipesList from "../components/planned-recipes-list";
import RecipeSelectionList from "../components/recipe-selection-list";
import MealPlannerCard from "../components/mealplanner-card";
import Footer from "../components/footer";
import {
  convertIngredient,
  calculatePricePerUnit,
} from "../functions/recipe-ingredient-calculations";

function MealPlanner() {
  const [token] = useCookies(["api-token"]);
  const [recipes, setRecipes] = useState([]);
  const [recipe, setRecipe] = useState("");
  const [portions, setPortions] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateList, setDateList] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [dateElements, setDateElements] = useState(
    <div>No dates selected</div>
  );
  const [selectedRecipe, setSelectedRecipe] = useState();
  const [plannedRecipes, setPlannedRecipes] = useState([]);
  const [recipeTotalList, setRecipeTotalList] = useState([]);
  const [ingredientList, setIngredientList] = useState([]);
  const [activeMealType, setActiveMealType] = useState("");
  const [hasIncorrectConversions, setHasIncorrectConversions] = useState(false);

  //
  // useEffects
  //
  // Look for API-token and redirect to login if it is missing
  // Run whenever token changes
  useEffect(() => {
    if (!token["api-token"]) {
      window.location.href = "/login";
    } else {
      API.getRecipeList(token["api-token"])
        .then((resp) => setRecipes(resp))
        .catch((error) => console.log(error));
    }
  }, [token]);

  useEffect(() => {
    API.loadDates(token["api-token"]).then((resp) => loadInitialDates(resp));
  }, []);

  // Create a new date list whenever startdate or enddate changes
  useEffect(() => {
    setDateList(getDates(new Date(startDate), new Date(endDate)));
  }, [startDate, endDate]);

  // Re-create elements whenever value changes, otherwise the previous recipe is added
  // on the first click. Need to fix some other way...
  useEffect(() => {
    createDateElements();
  }, [recipe, portions, activeMealType]);

  // Fetch planned recipes from the API  and create a planned recipe list whenever the date list changes
  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      let sd = new Date(startDate);
      let ed = new Date(endDate);

      let sdFormatted = sd.toISOString().split("T")[0];
      let edFormatted = ed.toISOString().split("T")[0];

      API.getPlannedRecipeList(
        { start_date: sdFormatted, end_date: edFormatted },
        token["api-token"]
      )
        .then((resp) => setPlannedRecipes(resp))
        .catch((error) => console.log(error));

      createWeeks();
    }
  }, [dateList, endDate, startDate, token]);

  // Create html elements and shopping list whenever the planned recipe list changes
  useEffect(() => {
    createDateElements();
    createIngredientList();
    createRecipeTotalList();
  }, [plannedRecipes]);

  //
  // functions
  //
  // Load saved dates
  function loadInitialDates(cachedDates) {
    setStartDate(cachedDates.start_date);
    setEndDate(cachedDates.end_date);
  }

  //Create weeks based on selected dates
  function createWeeks() {
    let sd = new Date(startDate);

    let weekDayStart = sd.getDay();

    // Changes sunday to day 7 instead of day 0
    if (weekDayStart === 0) {
      weekDayStart = 7;
    }

    // Change the weekday index so it is 0-6
    weekDayStart -= 1;

    let weeks = [];
    let dates = dateList;
    let firstWeek = true;

    for (let i = 0; i < dates.length; i += 7) {
      if (firstWeek) {
        firstWeek = false;
        weeks.push(dates.slice(i, 7 - weekDayStart));
      } else {
        weeks.push(dates.slice(i - weekDayStart, i + 7 - weekDayStart));
      }
    }

    setWeeks(weeks);
  }

  // Save currently selected dates to user
  function saveDates() {
    API.saveDates(
      { start_date: startDate, end_date: endDate },
      token["api-token"]
    ).then((resp) => console.log(resp));
  }

  // Creates a shopping list from the planned recipes
  const createIngredientList = () => {
    let plannedIngredients = {};

    // Iterate through all planned recipes
    plannedRecipes.map((p_recipe) => {
      if (p_recipe.include_shopping) {
        // Iterate through all ingredients in each recipe and add to shoppinglist
        p_recipe.recipe.ingredients.map((ri) => {
          let recipeUnit = ri.unit;
          let shopUnit = ri.ingredient.shopping_unit;
          let totalAmount =
            (ri.amount / p_recipe.recipe.portions) * p_recipe.portions;

          // Unit conversion function based on ingredient data, returns array with converted value [0] and bool [1].
          // Bool is true if any data was missing in the conversion and had to use a default value, e.g., density.
          let conversionResult = convertIngredient(
            recipeUnit,
            shopUnit,
            totalAmount,
            ri.ingredient
          );

          let pricePerUnit = calculatePricePerUnit(shopUnit, ri.ingredient);

          if (ri.ingredient.name in plannedIngredients) {
            plannedIngredients[ri.ingredient.name]["amount"] =
              conversionResult[0] +
              parseFloat(plannedIngredients[ri.ingredient.name]["amount"]);
            if (conversionResult[1]) {
              plannedIngredients[ri.ingredient.name]["iConvert"] = true;
              setHasIncorrectConversions(true);
            }
          } else {
            plannedIngredients[ri.ingredient.name] = {
              amount: conversionResult[0],
              unit: ri.ingredient.shopping_unit.abbrev,
              price_per_unit: pricePerUnit,
              iConvert: conversionResult[1],
            };
          }
        });
      }
    });

    /* Taking the plannedIngredients object and converting it into an array of objects. */
    let arrIngredients = Object.entries(plannedIngredients).map(
      ([name, amount]) => ({ name, ...amount })
    );
    setIngredientList(arrIngredients);
  };

  // Create a list of planned recipes and total portions planned
  const createRecipeTotalList = () => {
    let plannedTotal = {};
    plannedRecipes.map((p_recipe) => {
      let portions = p_recipe.portions;

      if (p_recipe.recipe.name in plannedTotal) {
        plannedTotal[p_recipe.recipe.name]["portions"] =
          portions + parseFloat(plannedTotal[p_recipe.recipe.name]["portions"]);
      } else {
        plannedTotal[p_recipe.recipe.name] = {
          portions: portions,
        };
      }
    });
    let arrRecipes = Object.entries(plannedTotal).map(([name, portions]) => ({
      name,
      ...portions,
    }));
    setRecipeTotalList(arrRecipes);
  };

  const changeActiveMealType = (mealType) => {
    setActiveMealType(mealType);
  };

  // Returns an array of dates between two dates
  function getDates(startDate, endDate) {
    const dates = [];
    let currentDate = startDate;
    const addDays = function (days) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  }

  // Set the selected recipe to view its details
  const showRecipeDetails = (recipe) => {
    setSelectedRecipe(recipe);
  };

  // Close the current recipe
  const closeRecipeDetails = () => {
    setSelectedRecipe(null);
  };

  // Add a planned recipe in the DB
  const addRecipeToPlan = (date) => {
    API.createPlannedRecipe(
      {
        recipe: { name: recipe },
        portions: portions,
        date: date,
        meal_type: activeMealType,
        include_shopping: true,
      },
      token["api-token"]
    )
      .then((resp) => updatePlannedRecipes(resp))
      .catch((error) => console.log(error));
  };

  // Update a planned recipe in the DB
  const updatePlannedRecipe = (planned_recipe, bInclude) => {
    API.updatePlannedRecipe(
      planned_recipe.id,
      {
        include_shopping: bInclude,
      },
      token["api-token"]
    )
      .then((resp) => updatePlannedRecipes(resp))
      .catch((error) => console.log(error));
  };

  // Update the list of planned recipes
  const updatePlannedRecipes = (plannedRecipe) => {
    if (plannedRecipe.message) {
      alert(plannedRecipe.message);
    } else {
      let updated = false;

      let newArr = plannedRecipes.map((el) => {
        if (el.id === plannedRecipe.id) {
          updated = true;
          return plannedRecipe;
        }
        return el;
      });

      if (!updated) {
        newArr.push(plannedRecipe);
      }

      setPlannedRecipes(newArr);
    }
  };

  // Set the recipe that should be added when + is clicked on a date
  const setActiveRecipe = (recipe_name) => {
    setRecipe(recipe_name);
  };

  // Delete a planned recipe from the DB
  const deletePlannedRecipe = (planned_recipe_id) => {
    API.deletePlannedRecipe(planned_recipe_id, token["api-token"]);
    removePlannedRecipe(planned_recipe_id);
  };

  // Remove a planned recipe from the planned recipe list
  const removePlannedRecipe = (planned_recipe_id) => {
    const newPlannedRecipeList = plannedRecipes.filter(
      (recipe) => recipe.id !== planned_recipe_id
    );

    setPlannedRecipes(newPlannedRecipeList);
  };

  // Create the day elements for the planner
  const createDateElements = () => {
    var blanks = [];

    // Create blank elements to align the days with their weekday
    if (startDate) {
      let sd = new Date(startDate);
      //fix because Sunday is 0 index instead of Monday
      if (sd.getDay() === 0) {
        for (let i = 0; i < 6; i++) {
          blanks.push(<div key={"blank" + i} className="blanks"></div>);
        }
      } else {
        for (let i = 1; i < sd.getDay(); i++) {
          blanks.push(<div key={"blank" + i} className="blanks"></div>);
        }
      }
    }

    // Create all the days and add their respective planned recipes
    let newDateElements = (
      <React.Fragment>
        {weeks.map((week, i) => {
          return (
            <React.Fragment key={i}>
              {i !== 0 ? <hr></hr> : null}
              <div className="week-container date-banner">
                {i === 0
                  ? blanks.map((blank) => {
                      return blank;
                    })
                  : null}
                {week.map((date) => {
                  return (
                    <div
                      key={"dateheader" + date.getDay() + date.getMonth()}
                      className="cal-card-header"
                    >
                      <div className="ps-2">{date.getDate()}</div>
                      <FontAwesomeIcon
                        onClick={() =>
                          addRecipeToPlan(date.toISOString().split("T")[0])
                        }
                        className="pe-2"
                        icon={faPlus}
                      />
                    </div>
                  );
                })}
              </div>

              {/* Breakfast */}
              <div className="week-mealtype-banner breakfast-banner">
                Breakfast
              </div>
              <div className="week-container meal-row breakfast-back">
                {i === 0
                  ? blanks.map((blank) => {
                      return blank;
                    })
                  : null}
                {week.map((date) => {
                  return (
                    <div
                      key={"breakfast" + date.getDay() + date.getMonth()}
                      className="cal-card"
                    >
                      <MealPlannerCard
                        key={"" + date.toISOString().split("T")[0]}
                        date={date}
                        plannedRecipes={plannedRecipes
                          .filter(
                            (recipe) =>
                              recipe.date === date.toISOString().split("T")[0]
                          )
                          .filter((recipe) => recipe.meal_type === "Breakfast")}
                        showRecipeDetails={showRecipeDetails}
                        deletePlannedRecipe={deletePlannedRecipe}
                        addRecipeToPlan={addRecipeToPlan}
                        updatePlannedRecipe={updatePlannedRecipe}
                      />
                    </div>
                  );
                })}
              </div>

              {/* Lunch */}
              <div className="week-mealtype-banner lunch-banner">Lunch</div>
              <div className="week-container meal-row lunch-back">
                {i === 0
                  ? blanks.map((blank) => {
                      return blank;
                    })
                  : null}

                {week.map((date) => {
                  return (
                    <div
                      key={"lunch" + date.getDay() + date.getMonth()}
                      className="cal-card"
                    >
                      <MealPlannerCard
                        key={"" + date.toISOString().split("T")[0]}
                        date={date}
                        plannedRecipes={plannedRecipes
                          .filter(
                            (recipe) =>
                              recipe.date === date.toISOString().split("T")[0]
                          )
                          .filter((recipe) => recipe.meal_type === "Lunch")}
                        showRecipeDetails={showRecipeDetails}
                        deletePlannedRecipe={deletePlannedRecipe}
                        addRecipeToPlan={addRecipeToPlan}
                        updatePlannedRecipe={updatePlannedRecipe}
                      />
                    </div>
                  );
                })}
              </div>

              {/* Dinner */}
              <div className="week-mealtype-banner dinner-banner">Dinner</div>
              <div className="week-container meal-row dinner-back">
                {i === 0
                  ? blanks.map((blank) => {
                      return blank;
                    })
                  : null}

                {week.map((date) => {
                  return (
                    <div
                      key={"dinner" + date.getDay() + date.getMonth()}
                      className="cal-card"
                    >
                      <MealPlannerCard
                        key={"" + date.toISOString().split("T")[0]}
                        date={date}
                        plannedRecipes={plannedRecipes
                          .filter(
                            (recipe) =>
                              recipe.date === date.toISOString().split("T")[0]
                          )
                          .filter((recipe) => recipe.meal_type === "Dinner")}
                        showRecipeDetails={showRecipeDetails}
                        deletePlannedRecipe={deletePlannedRecipe}
                        addRecipeToPlan={addRecipeToPlan}
                        updatePlannedRecipe={updatePlannedRecipe}
                      />
                    </div>
                  );
                })}
              </div>

              {/* Other */}
              <div className="week-mealtype-banner other-banner">Other</div>
              <div className="week-container meal-row other-back">
                {i === 0
                  ? blanks.map((blank) => {
                      return blank;
                    })
                  : null}

                {week.map((date) => {
                  return (
                    <div
                      key={"other" + date.getDay() + date.getMonth()}
                      className="cal-card"
                    >
                      <MealPlannerCard
                        key={"" + date.toISOString().split("T")[0]}
                        date={date}
                        plannedRecipes={plannedRecipes
                          .filter(
                            (recipe) =>
                              recipe.date === date.toISOString().split("T")[0]
                          )
                          .filter((recipe) => recipe.meal_type === "")}
                        showRecipeDetails={showRecipeDetails}
                        deletePlannedRecipe={deletePlannedRecipe}
                        addRecipeToPlan={addRecipeToPlan}
                        updatePlannedRecipe={updatePlannedRecipe}
                      />
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
    setDateElements(newDateElements);
  };

  // Render
  return (
    <React.Fragment>
      <Navbar />
      <div className="zc-page">
        <div>
          <div className="calendar-container">
            <div className="date-pick-container">
              <div>
                <label>From:</label>
                <input
                  type="date"
                  value={startDate}
                  className="form-control w-auto"
                  onChange={(evt) => setStartDate(evt.target.value)}
                />
              </div>
              <div>
                <label>To:</label>
                <input
                  type="date"
                  value={endDate}
                  className="form-control w-auto"
                  onChange={(evt) => setEndDate(evt.target.value)}
                />
              </div>
              <div className="d-flex flex-column">
                <button className="form-control mt-auto" onClick={saveDates}>
                  Save dates
                </button>
              </div>
            </div>
            <div className="flexrow">
              <div className="day-header">Monday</div>
              <div className="day-header">Tuesday</div>
              <div className="day-header">Wednesday</div>
              <div className="day-header">Thursday</div>
              <div className="day-header">Friday</div>
              <div className="day-header">Saturday</div>
              <div className="day-header">Sunday</div>
            </div>
            <hr />
            <div className="">{dateElements}</div>
          </div>
        </div>
        <div className="collection-container">
          {selectedRecipe ? (
            <div className="rounded-box-container mealplanner-recipe">
              <RecipeDetails
                closeRecipeDetails={closeRecipeDetails}
                recipe={selectedRecipe}
              />
            </div>
          ) : null}
          <div className="rounded-box-container recipe-selection-container">
            <h5>Select a recipe</h5>
            <RecipeSelectionList
              setPortions={setPortions}
              changeActiveMealType={changeActiveMealType}
              setActiveRecipe={setActiveRecipe}
              recipes={recipes}
              showRecipeDetails={showRecipeDetails}
            />
          </div>
          <div
            className="rounded-box-container shoppinglist-container"
            style={{ display: "inline-block" }}
          >
            <h5>Shopping List</h5>
            <div>
              <Table striped bordered hover size="sm" className="w-auto">
                <thead>
                  <tr>
                    <th className="p-2 pe-5">Ingredient</th>
                    <th className="p-2">Amount</th>
                    <th className="p-2">Unit</th>
                    <th className="p-2">Total Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <ShoppingIngredientList ingredientList={ingredientList} />
                </tbody>
              </Table>
            </div>
            {hasIncorrectConversions ? (
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1, width: 0 }}>
                  <i>
                    * this ingredient have been converted with insufficient
                    weight data.
                  </i>
                </div>
              </div>
            ) : null}
          </div>
          <div className="rounded-box-container planned-meals-container">
            <h5>Planned Meals</h5>
            <Table striped bordered hover size="sm" className="w-auto">
              <thead>
                <tr>
                  <th className="p-2 pe-5">Recipe</th>
                  <th className="p-2">Port.</th>
                </tr>
              </thead>
              <tbody>
                <PlannedRecipesList recipeTotalList={recipeTotalList} />
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default MealPlanner;
