import React from "react";

import MealPlannerRecipe from "../components/mealplanner-recipe";

function MealPlannerCard(props) {
  //const [plannedRecipes, setPlannedRecipes] = useState([]);

  const plannedRecipes = props.plannedRecipes.sort((a, b) => {
    let valA = a.recipe.name;
    let valB = b.recipe.name;

    if (valA < valB) {
      return -1;
    } else {
      return 1;
    }
  });

  const updateInclude = (p_recipe, bInclude) => {
    props.updatePlannedRecipe(p_recipe, bInclude);
  };

  const showRecipeDetails = (recipe) => {
    props.showRecipeDetails(recipe);
  };

  const deletePlannedRecipe = (recipe) => {
    props.deletePlannedRecipe(recipe);
  };

  return (
    <React.Fragment>
      {plannedRecipes.map((plannedRecipe) => {
        return (
          <MealPlannerRecipe
            key={plannedRecipe.id}
            planned_recipe={plannedRecipe}
            showRecipeDetails={showRecipeDetails}
            deletePlannedRecipe={deletePlannedRecipe}
            updateInclude={updateInclude}
          />
        );
      })}
    </React.Fragment>
  );
}

export default MealPlannerCard;
