import React from "react";
import { Link } from "react-router-dom";
import "../css/footer.css";

function Footer() {
  return (
    <div className="footer">
      <div>
        Contact:{" "}
        <Link
          to="#"
          onClick={() => (window.location = "mailto:martin@zcame.com")}
        >
          martin@zcame.com
        </Link>
      </div>
    </div>
  );
}

export default Footer;
