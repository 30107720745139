import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { calculatePricePerUnit } from "../functions/recipe-ingredient-calculations";

function RecipeIngredientList(props) {
  const [portions, setPortions] = useState(props.recipe.portions);

  var recipeCost = props.recipeCost;

  return (
    <React.Fragment>
      <h4>
        Ingredients for{" "}
        <input
          className="portions-input"
          type="number"
          value={portions}
          onChange={(evt) => setPortions(evt.target.value)}
        />{" "}
        portions
      </h4>
      <Table striped bordered hover size="sm" className="w-auto">
        <thead>
          <tr>
            <th className="p-2">Amount</th>
            <th className="p-2">Unit</th>
            <th className="p-2 pe-5">Ingredient</th>
            <th className="p-2">Cost per portion (%)</th>
          </tr>
        </thead>
        <tbody>
          {props.recipe.ingredients.map((ri) => {
            let costPerUnit = calculatePricePerUnit(ri.unit, ri.ingredient);
            console.log(ri);
            var cost = ri.amount * costPerUnit;
            return (
              <tr key={ri.id}>
                <td className="p-2 text-end">
                  {Math.round(
                    (ri.amount / props.recipe.portions) * portions * 100
                  ) / 100}{" "}
                </td>
                <td className="p-2">{ri.unit.abbrev}</td>
                <td className="p-2">{ri.ingredient.name}</td>
                <td className="p-2">
                  <div className="d-flex">
                    <div className="col">
                      {" "}
                      {cost > 0
                        ? Math.round((cost / props.recipe.portions) * 100) / 100
                        : 0}{" "}
                      kr{" "}
                    </div>
                    <div className="col percent-text">
                      <i>
                        {cost > 0
                          ? Math.round((cost / recipeCost) * 10000) / 100
                          : 0}
                        %
                      </i>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </React.Fragment>
  );
}

export default RecipeIngredientList;
