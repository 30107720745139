import React, { useState, useEffect } from "react";
import API from "../API-service";
import { useCookies } from "react-cookie";

// imported components
import Navbar from "../components/navbar";
import RecipeList from "../components/recipe-list";
import RecipeDetails from "../components/recipe-details";
import RecipeForm from "../components/recipe-form";
import Footer from "../components/footer";
import SideBarRecipes from "../components/sidebar-recipes";

function Recipes() {
  const [token] = useCookies(["api-token"]);
  const [recipes, setRecipes] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [units, setUnits] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [editRecipe, setEditRecipe] = useState(null);
  const [ingredientNames, setIngredientNames] = useState([]);
  const [saved, setSaved] = useState(true);

  //
  // useEffects
  //
  // Look for API-token and redirect to login if it is missing
  // Run whenever token changes
  useEffect(() => {
    if (!token["api-token"]) {
      window.location.href = "/login";
    } else {
      API.getRecipeList(token["api-token"])
        .then((resp) => setRecipes(resp))
        .catch((error) => console.log(error));

      API.getIngredientList(token["api-token"])
        .then((resp) => setIngredients(resp))
        .catch((error) => console.log(error));

      API.getUnitList(token["api-token"])
        .then((resp) => setUnits(resp))
        .catch((error) => console.log(error));
    }
  }, [token]);

  // Create new array for autocomplete whenever 'ingredients' change
  useEffect(() => {
    createIngredientNameList();
  }, [ingredients]);

  //
  // functions
  //
  // Create an array of ingredient names to use for the autocomplete function.
  const createIngredientNameList = () => {
    let tempArray = [];
    ingredients.map((ingredient) => {
      tempArray = [...tempArray, ingredient.name];
    });
    setIngredientNames(tempArray);
  };

  // Fetch ingredients from the DB to update the ingredient autocomplete array
  // in case new ingredients was created when a recipe was saved.
  const updateIngredientAutocomplete = () => {
    API.getIngredientList(token["api-token"])
      .then((resp) => setIngredients(resp))
      .catch((error) => console.log(error));
    createIngredientNameList();
  };

  // Close the current recipe
  const closeRecipeDetails = () => {
    setSelectedRecipe(null);
  };

  // Update the recipe being viewed with the clicked recipe
  const recipeClicked = (recipe) => {
    setEditRecipe(null);
    setSelectedRecipe(recipe);
  };

  // Update the recipe being edited with new values, used after
  // rate API to update the higlighted stars
  const updateRecipe = (recipe) => {
    setEditRecipe(recipe);
  };

  // Set recipe to edit mode
  const editClicked = (recipe) => {
    setEditRecipe(recipe);
  };

  // Complete actions after respons from save API
  const saveClicked = (recipe, created) => {
    if (recipe.message) {
      alert(recipe.message);
    } else {
      setSaved(true);
      if (created) {
        addNewRecipe(recipe);
      } else {
        updateRecipeList(recipe);
      }
      setEditRecipe(null);
      setSelectedRecipe(recipe);
      updateIngredientAutocomplete();
    }
  };

  // Remove a deleted recipe from the recipe array
  const deleteClicked = (deletedRecipe) => {
    setEditRecipe(null);
    setSelectedRecipe(null);

    const newRecipeList = recipes.filter(
      (recipe) => recipe.id !== deletedRecipe.id
    );

    setRecipes(newRecipeList);
  };

  // Turn off editing for the recipe currently being edited
  const closeEditRecipe = () => {
    setEditRecipe(null);
  };

  // Update a recipes values in the recipe array
  const updateRecipeList = (updatedRecipe) => {
    const newRecipeList = recipes.map((recipe) => {
      if (recipe.id === updatedRecipe.id) {
        return updatedRecipe;
      }
      return recipe;
    });
    setRecipes(newRecipeList);
  };

  // Add newly created recipe to the recipe array
  const addNewRecipe = (newRecipe) => {
    const newRecipeList = [...recipes, newRecipe];
    setRecipes(newRecipeList);
  };

  const createNewRecipe = () => {
    setSelectedRecipe(null);
    editClicked({
      name: "",
      instructions: "",
      stars: 0,
      portions: 4,
      ingredients: [],
    });
  };

  //
  // CSS functions
  //
  // Open sidemenu
  function openNav() {
    document.getElementById("sidebar").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
  }

  // Close sidemenu
  function closeNav() {
    document.getElementById("sidebar").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
  }

  // Rendering
  return (
    <React.Fragment>
      <Navbar />
      <SideBarRecipes
        recipes={recipes.sort((a, b) => a.name.localeCompare(b.name))}
        recipeClicked={recipeClicked}
        createNewRecipe={createNewRecipe}
      />
      <div className="zc-page">
        <div>
          <div id="main">
            <div className="collection-container">
              {editRecipe ? (
                <div className="rounded-box-container recipe">
                  <RecipeForm
                    setSaved={setSaved}
                    saved={saved}
                    closeEditRecipe={closeEditRecipe}
                    units={units}
                    saveClicked={saveClicked}
                    updateRecipe={updateRecipe}
                    addNewRecipe={addNewRecipe}
                    recipe={editRecipe}
                    deleteClicked={deleteClicked}
                    ingredientNames={ingredientNames}
                  />
                </div>
              ) : (
                <div className="rounded-box-container recipe">
                  <RecipeDetails
                    editIcon={true}
                    editClicked={editClicked}
                    deleteClicked={deleteClicked}
                    closeRecipeDetails={closeRecipeDetails}
                    recipe={selectedRecipe}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Recipes;
