import React, { useState, useEffect } from "react";

function ShoppingListRow(props) {
  const [highlighted, setHighlighted] = useState(false);
  const ingredient = props.ingredient;

  const clickHandler = () => {
    setHighlighted(!highlighted);
  };

  return (
    <tr
      className={
        highlighted ? "shopping-list-row highlighted" : "shopping-list-row"
      }
      onClick={() => clickHandler()}
    >
      <td>{ingredient.name}</td>
      <td style={{ textAlign: "right" }}>
        {Math.round((ingredient.amount + Number.EPSILON) * 100) / 100}
        {ingredient.iConvert ? "*" : null}{" "}
      </td>
      <td>{ingredient.unit}</td>
      <td style={{ textAlign: "right" }}>
        {Math.round(ingredient.price_per_unit * ingredient.amount * 100) / 100}{" "}
        kr
      </td>
    </tr>
  );
}

export default ShoppingListRow;
