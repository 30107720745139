import React, { useState, useEffect, useRef } from "react";
import { API } from "../API-service";
import { useCookies } from "react-cookie";

import "../css/ingredient-form.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";

function IngredientForm(props) {
  const [token] = useCookies(["api-token"]);

  const [ingredient, setIngredient] = useState(props.ingredient);
  const [name, setName] = useState(ingredient.name);
  const [price, setPrice] = useState(ingredient.price);
  const [kgPerPiece, setKgPerPiece] = useState(ingredient.kg_per_piece);
  const [priceUnit, setPriceUnit] = useState(ingredient.price_unit);
  const [amountForPrice, setAmountForPrice] = useState(
    ingredient.amount_for_price
  );
  const [shopUnit, setShopUnit] = useState(ingredient.shopping_unit);
  const [density, setDensity] = useState(ingredient.density_kg_per_l);
  const [calculatedDensity, setCalculatedDensity] = useState(0);
  const [measuredUnit, setMeasuredUnit] = useState(
    props.units.find(({ abbrev }) => abbrev === "l")
  );
  const [weightResultUnit, setWeightResultUnit] = useState(
    props.units.find(({ abbrev }) => abbrev === "kg")
  );

  useEffect(() => {
    props.setSaved(false);
  }, [shopUnit, name, price, priceUnit, amountForPrice]);

  useEffect(() => {
    changePriceUnit(priceUnit.abbrev);
    changeShopUnit(shopUnit.abbrev);
  }, []);

  const saveClicked = () => (evt) => {
    if (ingredient.id) {
      API.updateIngredient(
        ingredient.id,
        {
          name,
          price,
          price_unit: priceUnit,
          amount_for_price: amountForPrice,
          shopping_unit: shopUnit,
          density_kg_per_l: density,
          kg_per_piece: kgPerPiece,
        },
        token["api-token"]
      ).then((resp) => props.saveClicked(resp, false));
    } else {
      API.createIngredient(
        {
          name,
          price,
          price_unit: priceUnit,
          amount_for_price: amountForPrice,
          shopping_unit: shopUnit,
          density_kg_per_l: density,
          kg_per_piece: kgPerPiece,
        },
        token["api-token"]
      ).then((resp) => props.saveClicked(resp, true));
    }
  };

  const deleteClicked = () => (evt) => {
    if (
      window.confirm(
        `Are you sure you wish to delete '${ingredient.name}'? \nThis action cannot be reversed!`
      )
    ) {
      API.deleteIngredient(ingredient.id, token["api-token"]).then(() =>
        props.deleteClicked(ingredient)
      );
    }
  };

  const xClicked = () => (evt) => {
    if (!props.saved) {
      if (
        window.confirm(
          `This will close '${ingredient.name}' without saving, any changes made will be lost! \n\n Click OK if you wish to continue.`
        )
      ) {
        props.closeEditIngredient();
      }
    } else {
      props.closeEditIngredient();
    }
  };

  const changePriceUnit = (newUnit_abbrev) => {
    let newUnit;
    props.units.map((u) => {
      if (u.abbrev === newUnit_abbrev) newUnit = u;
    });

    setPriceUnit(newUnit);
  };

  const changeShopUnit = (newUnit_abbrev) => {
    let newUnit;
    props.units.map((u) => {
      if (u.abbrev === newUnit_abbrev) newUnit = u;
    });

    setShopUnit(newUnit);
  };

  const calculateDensity = () => {
    let volumeFactor = measuredUnit.conv_factor;
    let massFactor = weightResultUnit.conv_factor;

    let volume = document.getElementById("measured-amount").valueAsNumber;
    let mass = document.getElementById("weight-result").valueAsNumber;

    let volumeLiter = volume * volumeFactor;
    let massKilo = mass * massFactor;

    let d = massKilo / volumeLiter;

    setCalculatedDensity(d);
  };

  return (
    <div className="recipe-outer">
      {ingredient ? (
        <React.Fragment>
          <div className="edit-title">
            <h1>
              <input
                className="edit-title w-100"
                id="name"
                type="text"
                value={name}
                onChange={(evt) => setName(evt.target.value)}
                placeholder="New Ingredient"
              ></input>
            </h1>
          </div>

          <hr />

          <h5>
            <u>Shopping data</u>
          </h5>
          <p>
            <i>
              Ingredient prices are used to automatically generate an
              approximate cost per portion for recipes.
            </i>
          </p>
          <div className="ingredient-price-form">
            <div className="price me-2">
              <label htmlFor="price">Price in kr:</label>
              <input
                id="price"
                type="number"
                className="w-auto form-control"
                value={price}
                onChange={(evt) => setPrice(evt.target.valueAsNumber)}
              />
            </div>
            <div className="amount">
              <div className="me-2">
                <label
                  title="How much do you get for the specified price?"
                  htmlFor="amount"
                >
                  Amount:
                </label>
                <input
                  id="amount"
                  type="number"
                  className="w-auto form-control"
                  value={amountForPrice}
                  onChange={(evt) =>
                    setAmountForPrice(evt.target.valueAsNumber)
                  }
                />
              </div>
              <select
                className="w-auto mt-2 form-control"
                value={priceUnit.abbrev}
                onChange={(evt) => changePriceUnit(evt.target.value)}
              >
                {props.units.map((unit) => {
                  return <option key={unit.id}>{unit.abbrev}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="mt-2">
            <label title="This is the unit that will be displayed in shopping lists">
              Shopping List Unit:
            </label>
            <select
              className="w-auto me-2 form-control"
              value={shopUnit.abbrev}
              onChange={(evt) => changeShopUnit(evt.target.value)}
            >
              {props.units.map((unit) => {
                return <option key={unit.id}>{unit.abbrev}</option>;
              })}
            </select>
          </div>
          <hr />
          <h5>
            <u>Weight data</u>
          </h5>
          <p>
            <i>
              This data is necessary in order to correctly convert volume units
              to mass units and vice versa. For example, if a recipe specify
              butter in tablespoons but the shopping list is using grams.
            </i>
          </p>
          <div className="">
            <p>
              Enter density manually as kilograms per liter, or use the tool
              below to calculate the density.
            </p>
            <div className="density-form">
              <input
                type="number"
                className="w-auto me-2 form-control"
                value={density}
                onChange={(evt) => setDensity(evt.target.valueAsNumber)}
              />
              <p>kg/l</p>
            </div>
            <p className="pt-3">
              Enter the weight for 1 piece in kilograms (e.g. a tomato is about
              0.11 kg)
            </p>
            <div className="density-form">
              <input
                type="number"
                className="w-auto me-2 form-control"
                value={kgPerPiece}
                onChange={(evt) => setKgPerPiece(evt.target.valueAsNumber)}
              />
              <p>kg/pc</p>
            </div>
            <hr />
            <div className="mt-2">
              <h6>
                <u>Density calculator</u>
              </h6>
              <div className="density-calculator-outer">
                <p>
                  <b>Step 1</b>: Measure any amount of the ingredient and enter
                  the amount in the fields below, larger volumes give more
                  accurate results.
                </p>
                <div className="density-calculator-inner me-2">
                  <div>
                    <label title="How much of this ingredient did you weigh?">
                      Measured amount:
                    </label>
                    <input
                      id="measured-amount"
                      type="number"
                      className="w-auto me-2 form-control"
                    />
                  </div>
                  <select
                    className="w-auto mt-2 form-control"
                    onChange={(evt) =>
                      setMeasuredUnit(
                        props.units.find(
                          ({ abbrev }) => abbrev === evt.target.value
                        )
                      )
                    }
                  >
                    {props.units
                      .filter(({ measure_type }) => measure_type === "volume")
                      .map((unit) => {
                        return (
                          <option
                            selected={unit.abbrev === "l" ? "selected" : ""}
                            key={unit.id}
                          >
                            {unit.abbrev}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <p className="mt-3">
                  <b>Step 2</b>: Place the measured amount on a scale and enter
                  the weight in the fields below.
                </p>
                <div className="density-calculator-inner">
                  <div>
                    <label title="How much did the specified amount weigh?">
                      Weight result:
                    </label>
                    <input
                      id="weight-result"
                      type="number"
                      className="w-auto me-2 form-control"
                    />
                  </div>
                  <select
                    className="w-auto mt-2 form-control"
                    onChange={(evt) =>
                      setWeightResultUnit(
                        props.units.find(
                          ({ abbrev }) => abbrev === evt.target.value
                        )
                      )
                    }
                  >
                    {props.units
                      .filter(({ measure_type }) => measure_type === "mass")
                      .map((unit) => {
                        return (
                          <option
                            selected={unit.abbrev === "kg" ? "selected" : ""}
                            key={unit.id}
                          >
                            {unit.abbrev}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <p className="mt-3">
                  <b>Step 3</b>: Click on calculate to get the ingredients
                  density in kilograms per liter.
                </p>
                <div className="density-calculator-inner">
                  <button
                    className="w-auto form-control me-2"
                    onClick={() => calculateDensity()}
                  >
                    Calculate
                  </button>
                  <div>
                    <label>Result</label>
                    <input
                      type="number"
                      className="w-auto me-2 form-control"
                      value={calculatedDensity}
                      disabled={true}
                    />
                  </div>
                  <button
                    onClick={() => setDensity(calculatedDensity)}
                    className="w-auto form-control me-2"
                  >
                    Use value
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="controls-upper-right">
            <h2>
              <FontAwesomeIcon
                className="zc-btn"
                icon={faTrash}
                onClick={deleteClicked()}
              />
            </h2>
            <h2 className="pos-relative">
              {!props.saved ? <p className="save-star">*</p> : null}
              <FontAwesomeIcon
                className="zc-btn"
                icon={faSave}
                onClick={saveClicked()}
              />
            </h2>
            <h2 className="">
              <FontAwesomeIcon
                className="zc-btn"
                icon={faXmark}
                onClick={xClicked()}
              />
            </h2>
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
}

export default IngredientForm;
