import React, { useState, useEffect } from "react";

function PlannedRecipesList(props) {
  return (
    <React.Fragment>
      {props.recipeTotalList.map((recipe) => {
        return (
          <tr key={recipe.name}>
            <td>{recipe.name} </td>
            <td style={{ textAlign: "right" }}>{recipe.portions}</td>
          </tr>
        );
      })}
    </React.Fragment>
  );
}

export default PlannedRecipesList;
