import React, { useState, useEffect } from "react";

import ShoppingListRow from "./shopping-list-row";

function ShoppingIngredientList(props) {
  //const [totalCost, setTotalCost] = useState(0);
  var total = 0;
  return (
    <React.Fragment>
      {props.ingredientList.map((ingredient) => {
        total += ingredient.price_per_unit * ingredient.amount;
        return (
          <ShoppingListRow
            key={ingredient.name}
            ingredient={ingredient}
          ></ShoppingListRow>
        );
      })}
      <tr>
        <td colSpan={3}>
          <b>Total</b>
        </td>
        <td style={{ textAlign: "right" }}>
          <b>{Math.round(total * 100) / 100} kr</b>
        </td>
      </tr>
    </React.Fragment>
  );
}

export default ShoppingIngredientList;
