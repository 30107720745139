import React, { useState, useEffect } from "react";
import API from "../API-service";
import { useCookies } from "react-cookie";

// imported components
import Navbar from "../components/navbar";
import IngredientList from "../components/ingredient-list";
import IngredientDetails from "../components/ingredient-details";
import IngredientForm from "../components/ingredient-form";
import Footer from "../components/footer";
import SideBarIngredients from "../components/sidebar-ingredients";

function Ingredients() {
  const [token] = useCookies(["api-token"]);
  const [ingredients, setIngredients] = useState([]);
  const [units, setUnits] = useState([]);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [editIngredient, setEditIngredient] = useState(null);
  const [saved, setSaved] = useState(true);

  //
  // useEffects
  //
  // Look for API-token and redirect to login if it is missing
  // Run whenever token changes
  useEffect(() => {
    if (!token["api-token"]) {
      window.location.href = "/login";
    } else {
      API.getIngredientList(token["api-token"])
        .then((resp) => setIngredients(resp))
        .catch((error) => console.log(error));

      API.getUnitList(token["api-token"])
        .then((resp) => setUnits(resp))
        .catch((error) => console.log(error));
    }
  }, [token]);

  //
  // functions
  //
  // Open ingredient details for clicked ingredient
  const ingredientClicked = (ingredient) => {
    setEditIngredient(null);
    setSelectedIngredient(ingredient);
  };

  // Open edit mode for ingredient
  const editClicked = (ingredient) => {
    setEditIngredient(ingredient);
  };

  // Update ingredient list after delete from DB
  const deleteClicked = (deletedIngredient) => {
    setEditIngredient(null);
    setSelectedIngredient(null);

    const newIngredientList = ingredients.filter(
      (ingredient) => ingredient.id !== deletedIngredient.id
    );

    setIngredients(newIngredientList);
  };

  // Display message or update ingredient list after save to DB
  const saveClicked = (ingredient, created) => {
    if (ingredient.message) {
      alert(ingredient.message);
    } else {
      setSaved(true);
      if (created) {
        addNewIngredient(ingredient);
        setEditIngredient(null);
      } else {
        updateIngredientList(ingredient);
      }
      setSelectedIngredient(ingredient);
    }
  };

  // Add new ingredient to ingredient list
  const addNewIngredient = (newIngredient) => {
    const newIngredientList = [...ingredients, newIngredient];
    setIngredients(newIngredientList);
  };

  // Update ingredient list with new info after ingredient is saved
  const updateIngredientList = (updatedIngredient) => {
    const newIngredientList = ingredients.map((ingredient) => {
      if (ingredient.id === updatedIngredient.id) {
        return updatedIngredient;
      }
      return ingredient;
    });
    setIngredients(newIngredientList);
  };

  // Close the current recipe
  const closeIngredientDetails = () => {
    setSelectedIngredient(null);
  };

  // Turn off editing for the ingredient currently being edited
  const closeEditIngredient = () => {
    setEditIngredient(null);
  };

  // Create a new empty ingredient and open edit
  const createNewIngredient = () => {
    setSelectedIngredient(null);
    setEditIngredient({
      name: "",
      density_kg_per_l: -1,
      kg_per_piece: -1,
      price: 0,
      amount_for_price: 0,
      price_unit: {
        abbrev: "l",
      },
      shopping_unit: {
        abbrev: "l",
      },
    });
  };

  // Render
  return (
    <React.Fragment>
      <Navbar />
      <SideBarIngredients
        ingredients={ingredients.sort((a, b) => a.name.localeCompare(b.name))}
        ingredientClicked={ingredientClicked}
        createNewIngredient={createNewIngredient}
      />
      <div className="zc-page">
        <div id="main">
          <div className="collection-container">
            {editIngredient ? (
              <div className="rounded-box-container ingredient">
                <IngredientForm
                  setSaved={setSaved}
                  saved={saved}
                  saveClicked={saveClicked}
                  deleteClicked={deleteClicked}
                  editClicked={editClicked}
                  ingredient={editIngredient}
                  closeEditIngredient={closeEditIngredient}
                  units={units}
                />
              </div>
            ) : (
              <div className="rounded-box-container ingredient">
                <IngredientDetails
                  deleteClicked={deleteClicked}
                  editClicked={editClicked}
                  closeIngredientDetails={closeIngredientDetails}
                  ingredient={selectedIngredient}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Ingredients;
