const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

export class API {
  static login(body) {
    return fetch(`${API_DOMAIN}/auth/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  static register(body) {
    return fetch(`${API_DOMAIN}/users/users/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  static updateRecipe(recipe_id, body, token) {
    return fetch(`${API_DOMAIN}/recipes/recipes/${recipe_id}/update_recipe/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  static createRecipe(body, token) {
    return fetch(`${API_DOMAIN}/recipes/recipes/create_recipe/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  static updateIngredient(ingredient_id, body, token) {
    return fetch(
      `${API_DOMAIN}/recipes/ingredients/${ingredient_id}/update_ingredient/`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((resp) => resp.json());
  }

  static createIngredient(body, token) {
    return fetch(`${API_DOMAIN}/recipes/ingredients/create_ingredient/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  static getIngredient(body, token) {
    return fetch(`${API_DOMAIN}/recipes/ingredients/get_ingredient/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  static deleteRecipe(recipe_id, token) {
    return fetch(`${API_DOMAIN}/recipes/recipes/${recipe_id}/delete_recipe/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  static deleteIngredient(ingredient_id, token) {
    return fetch(
      `${API_DOMAIN}/recipes/ingredients/${ingredient_id}/delete_ingredient/`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    ).then((resp) => resp.json());
  }

  static getRecipeList(token) {
    return fetch(`${API_DOMAIN}/recipes/recipes/list_recipes/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  static rateRecipe(recipe_id, rating, token) {
    return fetch(`${API_DOMAIN}/recipes/recipes/${recipe_id}/rate_recipe/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ stars: rating }),
    }).then((resp) => resp.json());
  }

  static getIngredientList(token) {
    return fetch(`${API_DOMAIN}/recipes/ingredients/list_ingredients/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  static getUnitList(token) {
    return fetch(`${API_DOMAIN}/recipes/units/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  static getPlannedRecipeList(body, token) {
    return fetch(
      `${API_DOMAIN}/mealplanner/plannedrecipes/list_plannedrecipes/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((resp) => resp.json());
  }

  static createPlannedRecipe(body, token) {
    return fetch(
      `${API_DOMAIN}/mealplanner/plannedrecipes/create_plannedrecipe/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((resp) => resp.json());
  }

  static deletePlannedRecipe(id, token) {
    return fetch(
      `${API_DOMAIN}/mealplanner/plannedrecipes/${id}/delete_plannedrecipe/`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    ).then((resp) => resp.json());
  }

  static updatePlannedRecipe(planned_recipe_id, body, token) {
    return fetch(
      `${API_DOMAIN}/mealplanner/plannedrecipes/${planned_recipe_id}/update_plannedrecipe/`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((resp) => resp.json());
  }

  static saveDates(body, token) {
    return fetch(`${API_DOMAIN}/mealplanner/cached_dates/save_dates/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  static loadDates(token) {
    return fetch(`${API_DOMAIN}/mealplanner/cached_dates/load_dates/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }
}
export default API;
