import React, { useState, useEffect } from "react";
import API from "../API-service";

function RecipeList(props) {
  const [filterValue, setFilterValue] = useState("");

  const recipeClicked = (recipe) => (evt) => {
    props.recipeClicked(recipe);
  };

  return (
    <div>
      <label>
        <b>Filter:</b>
      </label>
      <input
        onChange={(evt) => setFilterValue(evt.target.value)}
        className="form-control mb-2"
        placeholder="Filter..."
      />
      <div className="zc-sidebar-list-container">
        {props.recipes
          .filter((recipe) => recipe.name.match(new RegExp(filterValue, "i")))
          .map((recipe) => {
            return (
              <div
                key={recipe.id}
                className="zc-sidebar-list-item"
                onClick={recipeClicked(recipe)}
              >
                {recipe.name}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default RecipeList;
