import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/_variables.css";
import "./css/index.css";
import "./css/sidemenu.css";
import "./css/footer.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { createRoot } from "react-dom/client";

import Auth from "./apps/auth";
import Recipes from "./apps/recipes";
import Ingredients from "./apps/ingredients";
import MealPlanner from "./apps/mealplanner";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<App />} />
          <Route exact path="/login" element={<Auth />} />
          <Route exact path="/recipes" element={<Recipes />} />
          <Route exact path="/ingredients" element={<Ingredients />} />
          <Route exact path="/mealplanner" element={<MealPlanner />} />
        </Routes>
      </BrowserRouter>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
