import React from "react";

import IngredientList from "./ingredient-list";

function SideBarIngredients(props) {
  const createNewIngredient = () => {
    props.createNewIngredient();
  };

  //
  // CSS functions
  //
  /* Set the width of the sidebar to 250px and the left margin of the page content to 250px */
  function openNav() {
    console.log("open");
    document.getElementById("sidebar").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
  }

  /* Set the width of the sidebar to 0 and the left margin of the page content to 0 */
  function closeNav() {
    document.getElementById("sidebar").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
  }

  return (
    <React.Fragment>
      <button className="openbtn" onClick={() => openNav()}>
        &gt;
      </button>
      <div id="sidebar" className="zc-sidebar">
        <button className="closebtn" onClick={() => closeNav()}>
          &lt;
        </button>
        <button className="new-btn" onClick={createNewIngredient}>
          New Ingredient
        </button>
        <div className="p-3">
          <IngredientList
            ingredients={props.ingredients}
            ingredientClicked={props.ingredientClicked}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
export default SideBarIngredients;
