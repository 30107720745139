import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faEdit,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import RecipeIngredientList from "./recipe-ingredient-list";
import { API } from "../API-service";
import { useCookies } from "react-cookie";
import { calculateRecipeCost } from "../functions/recipe-ingredient-calculations";

function RecipeDetails(props) {
  const recipe = props.recipe;

  const [token] = useCookies(["api-token"]);

  const [highlighted, setHighlighted] = useState(null);
  const [recipeCost, setRecipeCost] = useState(0);

  const editClicked = () => (evt) => {
    props.editClicked(recipe);
  };

  const deleteClicked = () => () => {
    if (
      window.confirm(
        `Are you sure you wish to delete '${recipe.name}'? \nThis action cannot be reversed!`
      )
    ) {
      API.deleteRecipe(recipe.id, token["api-token"]).then(() =>
        props.deleteClicked(recipe)
      );
    }
  };

  const xmarkClicked = () => () => {
    props.closeRecipeDetails();
  };

  useEffect(() => {
    setHighlighted(recipe ? recipe.stars : -1);
    setRecipeCost(calculateRecipeCost(recipe));
  }, [recipe]);

  return (
    <div>
      {recipe ? (
        <React.Fragment>
          <div className="details-title">
            <h1>{recipe.name}</h1>

            <div className="controls-upper-right">
              {props.editIcon ? (
                <React.Fragment>
                  <h2>
                    <FontAwesomeIcon
                      className="zc-btn"
                      icon={faTrash}
                      onClick={deleteClicked()}
                    />
                  </h2>
                  <h2>
                    <FontAwesomeIcon
                      className="zc-btn"
                      icon={faEdit}
                      onClick={editClicked()}
                    />
                  </h2>
                  <h2>
                    <FontAwesomeIcon
                      className="zc-btn"
                      icon={faXmark}
                      onClick={xmarkClicked()}
                    />
                  </h2>
                </React.Fragment>
              ) : (
                <h2>
                  <FontAwesomeIcon
                    className="zc-btn"
                    icon={faXmark}
                    onClick={xmarkClicked()}
                  />
                </h2>
              )}
            </div>
          </div>

          {/* Ranking */}
          {[...Array(5)].map((e, i) => {
            return (
              <FontAwesomeIcon
                key={i}
                icon={faStar}
                className={highlighted > i ? "orange" : ""}
              />
            );
          })}

          <hr />
          <div className="recipe-inner">
            <div>
              <h4>Instructions</h4>
              <p>{recipe.instructions}</p>
            </div>
            <div className="vl"></div>
            <div>
              <RecipeIngredientList recipeCost={recipeCost} recipe={recipe} />
            </div>
          </div>
          <hr />
          <div className="recipe-footer">
            <p>Cost per portion: {Math.round(recipeCost * 100) / 100} kr</p>
          </div>
        </React.Fragment>
      ) : (
        <h1 className="muted-text">
          <i>No recipe selected</i>
        </h1>
      )}
    </div>
  );
}

export default RecipeDetails;
