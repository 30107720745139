import React, { useState, useEffect } from "react";

function IngredientList(props) {
  const [filterValue, setFilterValue] = useState("");

  return (
    <div>
      <label>
        <b>Filter:</b>
      </label>
      <input
        onChange={(evt) => setFilterValue(evt.target.value)}
        className="form-control zc-sidebar-filter"
        placeholder="Filter..."
      />
      <div className="zc-sidebar-list-container">
        {props.ingredients
          .filter((recipe) => recipe.name.match(new RegExp(filterValue, "i")))
          .map((ingredient) => {
            return (
              <div
                key={ingredient.id}
                className="zc-sidebar-list-item"
                onClick={() => props.ingredientClicked(ingredient)}
              >
                {ingredient.name}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default IngredientList;
