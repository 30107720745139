import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faBook,
  faCarrot,
  faCalendarWeek,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";
import "../css/navbar.css";
import { useCookies } from "react-cookie";

function MyNavbar() {
  const [token, setToken, removeToken] = useCookies(["api-token"]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (token["api-token"]) {
      setIsLoggedIn(true);
    }
  }, [token]);

  const logout = () => {
    removeToken(["api-token"]);
  };

  return (
    <div className="zc-navbar">
      <div className="zc-navbar-header">
        {" "}
        <a href="/">Food Planner</a>
      </div>
      <div className="zc-navbar-vl"></div>
      {isLoggedIn ? (
        <div className="zc-navbar-controls">
          <a className="zc-navbar-link" href="/recipes">
            <FontAwesomeIcon icon={faBook} /> Recipes
          </a>
          <a className="zc-navbar-link" href="/ingredients">
            <FontAwesomeIcon icon={faCarrot} /> Ingredients
          </a>
          <a className="zc-navbar-link" href="/mealplanner">
            <FontAwesomeIcon icon={faCalendarWeek} /> Meal Planner
          </a>
        </div>
      ) : null}

      <div className="zc-navbar-controls-right">
        {isLoggedIn ? (
          <a className="zc-navbar-link" onClick={logout}>
            <FontAwesomeIcon icon={faArrowRightFromBracket} /> Logout
          </a>
        ) : (
          <a className="zc-navbar-link" href="/login">
            <FontAwesomeIcon icon={faArrowRightToBracket} /> Login
          </a>
        )}
      </div>
    </div>
    /*
    <Navbar className="zc-navbar" variant="dark" expand={false}>
      <Container fluid>
        <Navbar.Brand href="/">Food Planner</Navbar.Brand>
        <Nav.Link href="/recipes">
          <FontAwesomeIcon icon={faBook} /> Recipes
        </Nav.Link>
        <Nav.Link href="/ingredients">
          <FontAwesomeIcon icon={faCarrot} /> Ingredients
        </Nav.Link>
        <Nav.Link href="/mealplanner">
          <FontAwesomeIcon icon={faCalendarWeek} /> Meal Planning
        </Nav.Link>
        <Navbar.Toggle aria-controls="offcanvasNavbar" />
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              {isLoggedIn ? (
                <React.Fragment>
                  <Nav.Link href="/recipes">
                    <FontAwesomeIcon icon={faBook} /> Recipes
                  </Nav.Link>
                  <Nav.Link href="/ingredients">
                    <FontAwesomeIcon icon={faCarrot} /> Ingredients
                  </Nav.Link>
                  <Nav.Link href="/mealplanner">
                    <FontAwesomeIcon icon={faCalendarWeek} /> Meal Planning
                  </Nav.Link>
                  <hr />
                  <Nav.Link onClick={logout}>
                    <FontAwesomeIcon icon={faArrowRightFromBracket} /> Logout
                  </Nav.Link>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Nav.Link href="/login">
                    <FontAwesomeIcon icon={faArrowRightToBracket} /> Login
                  </Nav.Link>
                </React.Fragment>
              )}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
    */
  );
}
export default MyNavbar;
