import React, { useState, useEffect } from "react";

import { API } from "../API-service";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Autocomplete from "./autocomplete";
import Autosuggest from "react-autosuggest";
import { convertIngredient } from "../functions/recipe-ingredient-calculations";

function RIForm(props) {
  const [ingredientName, setIngredientName] = useState(
    props.ri.ingredient.name
  );
  const [unit, setUnit] = useState(props.ri.unit);
  const [amount, setAmount] = useState(props.ri.amount);
  const [convert, setConvert] = useState(false);
  const [ingredient, setIngredient] = useState(props.ri.ingredient);

  /* A hook that is called whenever the ingredient, unit, or amount changes. It updates the recipe
ingredient with the new values. */
  useEffect(() => {
    let newRI = props.ri;

    newRI.amount = amount;
    newRI.unit = unit;
    newRI.ingredient.name = ingredientName;

    props.updateRI(newRI);
  }, [ingredientName, unit, amount]);

  /**
   * If the user wants to convert the amount, then convert the amount, otherwise just change the unit.
   * @param toUnit_abbrev - the abbreviation of the new unit (e.g. "g")
   */
  const changeUnit = (toUnit_abbrev) => {
    let toUnit = props.units.find((u) => u.abbrev === toUnit_abbrev);
    let fromUnit = props.units.find((u) => u.abbrev === unit.abbrev);
    if (convert) {
      let conversionResult = convertIngredient(
        fromUnit,
        toUnit,
        amount,
        ingredient
      );
      if (conversionResult[0] > 0) {
        setAmount(conversionResult[0]);
        setUnit(toUnit);
      } else {
        alert("Conversion failed!");
        setUnit(toUnit);
      }
    } else {
      setUnit(toUnit);
    }
  };

  /**
   * When the user types in the input field, update the state of the ingredient variable.
   * @param val - the value of the input field
   */
  const updateIngredient = (val) => {
    setIngredientName(val);
  };

  /**
   * When the delete button is clicked, the index of the row is passed to the deleteClicked function,
   * which then calls the removeRI function in the parent component, passing the index of the row to be
   * deleted.
   * @param index - the index of the row in the table
   */
  const deleteClicked = (index) => (e) => {
    props.removeRI(index);
  };

  return (
    <React.Fragment>
      <td className="p-2 text-end">
        <input
          className="form-control recipe-ingredient amount"
          id={"amount" + props.ri.id}
          type={"number"}
          value={Math.round((amount + Number.EPSILON) * 1000) / 1000}
          onChange={(evt) => setAmount(evt.target.valueAsNumber)}
          autoFocus
        />
      </td>
      <td className="p-2 text-justify">
        <div className="row">
          <select
            className="col w-auto ms-3 me-2 form-control"
            value={unit.abbrev}
            onChange={(evt) => changeUnit(evt.target.value)}
          >
            {props.units.map((unit) => {
              return <option key={unit.id}>{unit.abbrev}</option>;
            })}
          </select>
          <input
            className="col w-auto form-check me-2 mt-2"
            type="checkbox"
            value={convert}
            onChange={() => setConvert(!convert)}
          />
        </div>
      </td>
      <td className="p-2">
        {/*<input
          type="text"
          onChange={(evt) => setIngredient(evt.target.value)}
          value={ingredient}
          />*/}
        <Autocomplete
          id="ingredient"
          placeholder="Ingredient name"
          updateValue={updateIngredient}
          suggestions={props.ingredientNames}
          startValue={ingredientName}
        />
      </td>
      <td>
        <FontAwesomeIcon
          className="ri-delete p-2"
          icon={faXmark}
          onClick={deleteClicked(props.index)}
        />
      </td>
    </React.Fragment>
  );
}

export default RIForm;
