import { Dropdown } from "bootstrap";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { calculateRecipeCost } from "../functions/recipe-ingredient-calculations";

function RecipeSelectionList(props) {
  const [isActive, setIsActive] = useState("");
  const [filterValue, setFilterValue] = useState("");

  const recipeClicked = (name) => {
    if (isActive === name) {
      setIsActive("");
      props.setActiveRecipe("");
    } else {
      setIsActive(name);
      props.setActiveRecipe(name);
    }
  };

  const showRecipeDetails = (recipe) => {
    props.showRecipeDetails(recipe);
  };

  return (
    <React.Fragment>
      <div className="recipe-selection-input-container">
        <div className="me-2 mb-2">
          <label>Meal Type:</label>
          <select
            className="form-control"
            onChange={(evt) => props.changeActiveMealType(evt.target.value)}
          >
            <option value={""}>-</option>
            <option value={"Breakfast"}>Breakfast</option>
            <option value={"Lunch"}>Lunch</option>
            <option value={"Dinner"}>Dinner</option>
          </select>
        </div>
        <div>
          <label>Portions:</label>
          <input
            defaultValue={1}
            className="form-control"
            type={"number"}
            onChange={(evt) => props.setPortions(evt.target.valueAsNumber)}
          />
        </div>
      </div>
      <input
        onChange={(evt) => setFilterValue(evt.target.value)}
        className="form-control"
        placeholder="Filter..."
      />

      <div
        id="recipe-list-container"
        className="recipe-selection-item-container"
      >
        {props.recipes
          .filter((recipe) => recipe.name.match(new RegExp(filterValue, "i")))
          .map((recipe) => {
            var recipeCost = calculateRecipeCost(recipe);
            return (
              <div
                key={recipe.name}
                onClick={(e) => recipeClicked(recipe.name)}
                className={
                  isActive === recipe.name
                    ? "recipe-selection-item selected"
                    : "recipe-selection-item"
                }
              >
                <div>{recipe.name}</div>
                <div> {Math.round(recipeCost * 100) / 100} kr</div>
                <FontAwesomeIcon
                  className="zc-btn"
                  icon={faBook}
                  onClick={() => showRecipeDetails(recipe)}
                />
              </div>
            );
          })}
      </div>
    </React.Fragment>
  );
}

export default RecipeSelectionList;
