export function convertIngredient(fromUnit, toUnit, amount, ingredient) {
  let conversionNote = false;

  let density = ingredient.density_kg_per_l;
  let kgPerPiece = ingredient.kg_per_piece;
  let factorFrom = fromUnit.conv_factor;
  let factorTo = toUnit.conv_factor;

  // Return same amount if the from and to units are the same
  if (fromUnit === toUnit) {
    return [amount, conversionNote];
  }

  let convertedAmount;

  // Conversion if the from and to units are of the same type
  if (fromUnit.measure_type === toUnit.measure_type) {
    convertedAmount = (amount * factorFrom) / factorTo;
    return [convertedAmount, conversionNote];
  }

  // Conversion if the fromUnit is "piece"
  if (fromUnit.measure_type === "piece") {
    if (!(kgPerPiece > 0) || kgPerPiece === undefined) {
      conversionNote = true;
      kgPerPiece = 1;
    }

    let amountInKg = amount * kgPerPiece;

    if (toUnit.measure_type === "mass") {
      convertedAmount = amountInKg / factorTo;
      return [convertedAmount, conversionNote];
    }

    if (toUnit.measure_type === "volume") {
      if (!(density > 0) || density === undefined) {
        conversionNote = true;
        density = 1;
      }
      convertedAmount = amountInKg / factorTo / density;
      return [convertedAmount, conversionNote];
    }
  }

  // Conversion if the fromUnit is "mass"
  if (fromUnit.measure_type === "mass") {
    if (toUnit.measure_type === "piece") {
      if (!(kgPerPiece > 0) || kgPerPiece === undefined) {
        conversionNote = true;
        kgPerPiece = 1;
      }
      let amountInKg = amount * factorFrom;
      let pieces = amountInKg / kgPerPiece;
      return [pieces, conversionNote];
    }

    if (toUnit.measure_type === "volume") {
      if (!(density > 0) || density === undefined) {
        conversionNote = true;
        density = 1;
      }
      convertedAmount = (amount * factorFrom) / factorTo / density;
      return [convertedAmount, conversionNote];
    }
  }

  // Conversion if the fromUnit is "volume"
  if (fromUnit.measure_type === "volume") {
    if (toUnit.measure_type === "piece") {
      if (!(kgPerPiece > 0) || kgPerPiece === undefined) {
        conversionNote = true;
        kgPerPiece = 1;
      }
      if (!(density > 0) || density === undefined) {
        conversionNote = true;
        density = 1;
      }
      let amountInKg = amount * factorFrom * density;
      let pieces = amountInKg / kgPerPiece;
      return [pieces, conversionNote];
    }

    if (toUnit.measure_type === "mass") {
      if (!(density > 0) || density === undefined) {
        conversionNote = true;
        density = 1;
      }
      convertedAmount = ((amount * factorFrom) / factorTo) * density;
      return [convertedAmount, conversionNote];
    }
  }
}

export function calculatePricePerUnit(unit, ingredient) {
  let priceUnit = ingredient.price_unit;
  let amountForPrice = ingredient.amount_for_price;
  let price = ingredient.price;

  if (price > 0 && amountForPrice > 0) {
    let conversionResult = convertIngredient(
      priceUnit,
      unit,
      amountForPrice,
      ingredient
    );

    let pricePerUnit = price / conversionResult[0];

    return pricePerUnit;
  }
  return 0;
}

export function calculateRecipeCost(recipe) {
  if (recipe === null) {
    return 0;
  }

  let total = 0;

  for (let i = 0; i < recipe.ingredients.length; i++) {
    let ri = recipe.ingredients[i];

    let pricePerUnit = calculatePricePerUnit(ri.unit, ri.ingredient);
    total += pricePerUnit * ri.amount;
  }
  return total / recipe.portions;
}
