import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { API } from "../API-service";
import { useCookies } from "react-cookie";

function IngredientDetails(props) {
  const ingredient = props.ingredient;

  const [token] = useCookies(["api-token"]);

  const editClicked = () => (evt) => {
    props.editClicked(ingredient);
  };

  const xmarkClicked = () => () => {
    props.closeIngredientDetails();
  };

  const deleteClicked = () => (evt) => {
    if (
      window.confirm(
        `Are you sure you wish to delete '${ingredient.name}'? \nThis action cannot be reversed!`
      )
    ) {
      API.deleteIngredient(ingredient.id, token["api-token"]).then(() =>
        props.deleteClicked(ingredient)
      );
    }
  };

  return (
    <div className="recipe-outer">
      {ingredient ? (
        <React.Fragment>
          <div className="details-title">
            <h1>{ingredient.name}</h1>
            <div className="controls-upper-right">
              <h2>
                <FontAwesomeIcon
                  className="zc-btn"
                  icon={faTrash}
                  onClick={deleteClicked()}
                />
              </h2>
              <h2>
                <FontAwesomeIcon
                  className="zc-btn"
                  icon={faEdit}
                  onClick={editClicked()}
                />
              </h2>
              <h2>
                <FontAwesomeIcon
                  className="zc-btn"
                  icon={faXmark}
                  onClick={xmarkClicked()}
                />
              </h2>
            </div>
          </div>

          <hr />

          <div>
            <h5>
              <u>Shopping data</u>
            </h5>
            <p>
              <i>
                Ingredient prices are used to automatically generate an
                approximate cost per portion for recipes.
              </i>
            </p>
            {ingredient.price > 0 && ingredient.amount_for_price > 0 ? (
              <p>
                This ingredient cost {ingredient.price} kr for{" "}
                {ingredient.amount_for_price} {ingredient.price_unit.name}.
              </p>
            ) : (
              <p>No price data is available for this ingredient.</p>
            )}
            <p>
              In shopping lists the unit used for this ingredient will be{" "}
              <b>{ingredient.shopping_unit.name}</b>.
            </p>
            <hr />
            <h5>
              <u>Weight data</u>
            </h5>
            <p>
              <i>
                This data is necessary in order to correctly convert volume
                units to mass units and vice versa. For example, if a recipe
                specify butter in tablespoons but the shopping list is using
                grams.
              </i>
            </p>
            {ingredient.density_kg_per_l > 0 ? (
              <p>
                The density of this ingredient is{" "}
                <b>
                  {Math.round(
                    (ingredient.density_kg_per_l + Number.EPSILON) * 1000
                  ) / 1000}
                </b>{" "}
                kg/l (or{" "}
                <b>
                  {Math.round(
                    (ingredient.density_kg_per_l + Number.EPSILON) * 1000
                  ) / 1000}
                </b>{" "}
                g/cm<sup>3</sup>).
              </p>
            ) : (
              <p>Density for this ingredient have not been set.</p>
            )}
            {ingredient.kg_per_piece > 0 ? (
              <p>
                1 x <b>{ingredient.name}</b> = {ingredient.kg_per_piece}{" "}
                kilograms.{" "}
              </p>
            ) : null}
          </div>
        </React.Fragment>
      ) : (
        <h1 className="muted-text">
          <i>No ingredient selected</i>
        </h1>
      )}
    </div>
  );
}

export default IngredientDetails;
