import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

function MealPlannerRecipe(props) {
  const [include, setInclude] = useState(props.planned_recipe.include_shopping);

  const includeChanged = () => {
    props.updateInclude(props.planned_recipe, !include);
    setInclude(!include);
  };

  const recipeClicked = () => {
    props.showRecipeDetails(props.planned_recipe.recipe);
  };

  const deleteClicked = () => {
    props.deletePlannedRecipe(props.planned_recipe.id);
  };

  return (
    <div className="mp-recipe-line">
      <div className="mp-recipe-name" onClick={() => recipeClicked()}>
        {props.planned_recipe.recipe.name}
      </div>
      <div className="vl-100 mx-1"></div>
      <div>
        <div className="">{props.planned_recipe.portions}</div>
        <input
          className="col w-auto form-check"
          checked={include}
          type="checkbox"
          onChange={() => includeChanged()}
        />
        <FontAwesomeIcon
          className="zc-btn"
          icon={faTrash}
          onClick={() => deleteClicked()}
        />
      </div>
    </div>
  );
}
export default MealPlannerRecipe;
