import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faSave,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { API } from "../API-service";
import RIForm from "./recipe-ingredient-form";
import { useCookies } from "react-cookie";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { calculateRecipeCost } from "../functions/recipe-ingredient-calculations";

function RecipeForm(props) {
  const [token] = useCookies(["api-token"]);

  const [recipe, setRecipe] = useState(props.recipe);
  const [highlighted, setHighlighted] = useState(null);
  const [instructions, setInstructions] = useState(recipe.instructions);
  const [name, setName] = useState(recipe.name);
  const [portions, setPortions] = useState(recipe.portions);
  const [recipeIngredients, setRecipeIngredients] = useState(
    recipe.ingredients
  );
  const [newRIId, setNewRIId] = useState(1);
  const [recipeCost, setRecipeCost] = useState(0);

  const highlightStars = (ix) => (evt) => {
    if (evt._reactName === "onMouseLeave") {
      setHighlighted(recipe.stars - 1);
    } else {
      setHighlighted(ix);
    }
  };

  useEffect(() => {
    props.setSaved(false);
  }, [instructions, name, portions, recipeIngredients]);

  useEffect(() => {
    setRecipeCost(calculateRecipeCost(recipe));
  });

  const saveClicked = () => (evt) => {
    if (!props.saved) {
      if (recipe.id) {
        API.updateRecipe(
          recipe.id,
          { name, instructions, portions, ingredients: recipeIngredients },
          token["api-token"]
        ).then((resp) => props.saveClicked(resp, false));
      } else {
        API.createRecipe(
          {
            name,
            instructions,
            portions,
            ingredients: recipeIngredients,
            stars: recipe.stars,
          },
          token["api-token"]
        ).then((resp) => props.saveClicked(resp, true));
      }
      //checkIngredientData();
    }
  };

  /*
// Attempt to update ingredient data based on name (doesn't work) 

  function checkIngredientData() {
    recipeIngredients.map((ri) => {
      if (!ri.ingredient.density_kg_per_l) {
        loadIngredientData(ri.ingredient.name);
      }
    });
  }

  function loadIngredientData(ingredientName) {
    API.getIngredient({ ingredient_name: ingredientName }, token["api-token"])
      .then((resp) => updateIngredientData(resp))
      .catch((error) => console.log(error));
  }

  function updateIngredientData(ingredientData) {
    console.log(recipeIngredients);
    let newArr = recipeIngredients.map((ri) => {
      if (ri.ingredient.name === ingredientData.name) {
        let updatedRI = ri;
        updatedRI.ingredient = ingredientData;
        return updatedRI;
      }
      return ri;
    });
    setRecipeIngredients(newArr);
    console.log(newArr);
  }
*/

  const deleteClicked = () => (evt) => {
    if (
      window.confirm(
        `Are you sure you wish to delete '${recipe.name}'? \nThis action cannot be reversed!`
      )
    ) {
      API.deleteRecipe(recipe.id, token["api-token"]).then(() =>
        props.deleteClicked(recipe)
      );
    }
  };

  const xClicked = () => (evt) => {
    if (!props.saved) {
      if (
        window.confirm(
          `This will close '${recipe.name}' without saving, any changes made will be lost! \n\n Click OK if you wish to continue.`
        )
      ) {
        props.closeEditRecipe();
      }
    } else {
      props.closeEditRecipe();
    }
  };

  const rateRecipe = (rating) => (evt) => {
    let temp = recipe;
    temp.stars = rating;
    setRecipe(temp);
    if (recipe.id) {
      API.rateRecipe(recipe.id, rating, token["api-token"]).then((resp) =>
        props.updateRecipe(resp)
      );
    }
    setHighlighted(rating - 1);
  };

  useEffect(() => {
    if (recipe) {
      setHighlighted(recipe ? recipe.stars - 1 : -1);
    }
  }, [recipe]);

  const addNewIngredient = () => {
    const emptyRI = {
      id: newRIId,
      ingredient: { name: "" },
      unit: { abbrev: "l" },
      added: true,
    };
    setNewRIId(newRIId + 1);
    setRecipeIngredients([...recipeIngredients, emptyRI]);
  };

  const updateRI = (updatedRI) => {
    const newRIList = recipeIngredients.map((ri) => {
      if (ri.id) {
        if (ri.id === updatedRI.id) {
          return updatedRI;
        }
        return ri;
      } else {
        return ri;
      }
    });
    setRecipeIngredients(newRIList);
  };

  useEffect(() => {}, [recipeIngredients]);

  const removeRI = (index) => {
    setRecipeIngredients([
      ...recipeIngredients.slice(0, index),
      ...recipeIngredients.slice(index + 1),
    ]);
  };

  return (
    <div>
      {recipe ? (
        <React.Fragment>
          <div className="edit-title">
            <h1>
              <input
                className="edit-title w-100"
                id="name"
                type="text"
                value={name}
                onChange={(evt) => setName(evt.target.value)}
                placeholder="New Recipe"
              ></input>
            </h1>
            <div className="controls-upper-right">
              <h2 className="">
                <FontAwesomeIcon
                  className="zc-btn"
                  icon={faTrash}
                  onClick={deleteClicked()}
                />
              </h2>
              <h2 className="pos-relative">
                {!props.saved ? <p className="save-star">*</p> : null}
                <FontAwesomeIcon
                  className="zc-btn"
                  icon={faSave}
                  onClick={saveClicked()}
                />
              </h2>
              <h2 className="">
                <FontAwesomeIcon
                  className="zc-btn"
                  icon={faXmark}
                  onClick={xClicked()}
                />
              </h2>
            </div>
          </div>

          {[...Array(5)].map((e, i) => {
            return (
              <FontAwesomeIcon
                key={i}
                icon={faStar}
                className={
                  highlighted > i - 1 ? "orange edit-rate" : "edit-rate"
                }
                onMouseEnter={highlightStars(i)}
                onMouseLeave={highlightStars(-1)}
                onClick={rateRecipe(i + 1)}
              />
            );
          })}

          <hr />
          <div className="recipe-inner">
            <div>
              <h4>Instructions</h4>
              <textarea
                className="form-control"
                rows={15}
                id="instructions"
                type="text"
                value={instructions}
                onChange={(evt) => setInstructions(evt.target.value)}
                placeholder="Instructions..."
              ></textarea>
            </div>
            <div className="vl"></div>
            <div>
              <h4>
                Ingredients for{" "}
                <input
                  className="portions-input"
                  type="number"
                  value={portions}
                  onChange={(evt) => setPortions(evt.target.value)}
                />{" "}
                portions
              </h4>

              <Table striped bordered hover size="sm" className="w-auto">
                <thead>
                  <tr>
                    <th className="p-2">Amount</th>
                    <th className="p-2">Unit</th>
                    <th className="p-2 pe-5">Ingredient</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {recipeIngredients.map((ri, i) => {
                    return (
                      <tr key={"ri" + ri.id + recipe.id}>
                        <RIForm
                          index={i}
                          removeRI={removeRI}
                          units={props.units}
                          ri={ri}
                          updateRI={updateRI}
                          ingredientNames={props.ingredientNames}
                        />
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Button className="py-2 my-2" onClick={addNewIngredient}>
                Add ingredient
              </Button>
            </div>
          </div>
          <hr />
          <div className="recipe-footer">
            <p>Cost per portion: {Math.round(recipeCost * 100) / 100} kr</p>
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
}

export default RecipeForm;
