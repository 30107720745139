import React, { useState, useEffect } from "react";
import API from "../API-service";
import { useCookies } from "react-cookie";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function Auth() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [isLoginView, setIsLoginView] = useState(true);

  const [token, setToken] = useCookies(["api-token"]);

  useEffect(() => {
    console.log(token);
    if (token["api-token"]) window.location.href = "/recipes";
  }, [token]);

  const loginClicked = () => {
    API.login({ username, password })
      .then((resp) => setToken("api-token", resp.token))
      .catch((e) => console.log(e));
  };

  const registerClicked = () => {
    API.register({ email: username, password })
      .then(() => loginClicked())
      .catch((e) => console.log(e));
  };

  return (
    <React.Fragment>
      <Navbar />
      <div className="zc-page">
        {isLoginView ? (
          <div className="col-12 col-md-8 col-lg-6 col-xl-4 col-xxl-3 mx-auto">
            <Form className="mx-2">
              <h2 className="mb-3">Login</h2>
              <Form.Group className="mb-3" controlId="username">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={(evt) => setUsername(evt.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={(evt) => setPassword(evt.target.value)}
                />
              </Form.Group>
              <Button variant="primary" onClick={loginClicked}>
                Login
              </Button>

              <p
                className="text-muted mt-2"
                role="button"
                onClick={() => setIsLoginView(false)}
              >
                Don't have an account? Click here to register!
              </p>
            </Form>
          </div>
        ) : (
          <React.Fragment>
            <Form className="col-4 mx-auto">
              <h2 className="mb-3">Register</h2>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={(evt) => setUsername(evt.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={(evt) => setPassword(evt.target.value)}
                />
              </Form.Group>
              <Button variant="primary" onClick={registerClicked}>
                Register
              </Button>

              <p
                className="text-muted mt-2"
                role="button"
                onClick={() => setIsLoginView(true)}
              >
                Already registered? Click here to login!
              </p>
            </Form>
          </React.Fragment>
        )}
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Auth;
